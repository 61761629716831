import React, { useMemo } from "react";

import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

// @ts-expect-error type
import logoExamedi from "~/src/images/examedi-logo.png";

type SEOProps = {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  imageDescription?: string | null;
  keywords?: string[] | null;
  index?: boolean | null;
  follow?: boolean | null;
};

type MetaTag = {
  key: string;
  name?: string | null;
  property?: string | null;
  content: string | string[] | null | undefined;
};

function useFollowTags(index: boolean | undefined | null, follow: boolean | undefined | null) {
  return useMemo(() => {
    return [
      index === true ? "index" : index === false ? "noindex" : undefined,
      follow === true ? "follow" : follow === false ? "nofollow" : undefined,
    ].filter(Boolean);
  }, [follow, index]);
}

export default function SEO({
  title: proposedTitle,
  description: proposedDescription,
  image: proposedImage,
  imageDescription: proposedImageDescription,
  keywords: proposedKeywords,
  follow = false, // Internal product (private)
  index = false, // Internal product (private)
  ...props
}: SEOProps) {
  const loct = useLocation();

  const host = "https://marketing-dashboard.examedi.com/";
  const path = loct.pathname;
  const url = new URL(path, host);
  const urlHref = url.href;

  const defaultTitle = "Examedi - Marketing Dashboard";
  const defaultDescription = "Examedi - Marketing Dashboard";
  const defaultKeywords = ["examedi", "dashboard", "marketing", "coupons"];
  const defaultImage = logoExamedi;
  const defaultImageDescription = "Logo de Examedi - Agenda tu examen en tan solo 3 minutos";

  const robots = useFollowTags(index, follow);

  const title = proposedTitle || defaultTitle;
  const description = proposedDescription || defaultDescription;
  const keywords = proposedKeywords || defaultKeywords;
  const imageHref = proposedImage || defaultImage;
  const imageDescription = proposedImageDescription || defaultImageDescription;
  const color = "#039Be5";

  const metatags = useMemo(() => {
    const og: MetaTag[] = [
      { key: "og:title", property: "og:title", content: title },
      { key: "og:image", property: "og:image", content: imageHref },
      { key: "og:image:alt", property: "og:image:alt", content: imageDescription },
      { key: "og:description", property: "og:description", content: description },
      { key: "og:site_name", property: "og:site_name", content: defaultTitle },
      { key: "og:url", property: "og:url", content: urlHref },
      { key: "og:type", property: "og:type", content: "website" },
      { key: "og:locale", property: "og:locale", content: "es_LA" },
    ];

    const twitter: MetaTag[] = [
      { key: "twitter:card", name: "twitter:card", content: "summary_large_image" },
      // { key: "twitter:site", name: "twitter:site", content: "@examedi" },
      // { key: "twitter:creator", name: "twitter:creator", content: "@examedi" },
      { key: "twitter:url", name: "twitter:url", content: urlHref },
      { key: "twitter:title", name: "twitter:title", content: title },
      { key: "twitter:description", name: "twitter:description", content: description },
      { key: "twitter:image", name: "twitter:image", content: imageHref },
      { key: "twitter:image:alt", name: "twitter:image:alt", content: imageDescription },
    ];

    const metas: MetaTag[] = [
      { key: "keywords", name: "keywords", content: keywords },
      { key: "viewport", name: "viewport", content: ["width=device-width", "initial-scale=1"] },
      { key: "theme-color", name: "theme-color", content: color },
      { key: "description", name: "description", content: description },
      { key: "mobile-web-app-capable", name: "mobile-web-app-capable", content: "yes" },
      { key: "apple-mobile-web-app-capable", name: "apple-mobile-web-app-capable", content: "yes" },
      {
        key: "apple-mobile-web-app-status-bar-style",
        name: "apple-mobile-web-app-status-bar-style",
        content: "black-translucent",
      },
      { key: "apple-mobile-web-app-title", name: "apple-mobile-web-app-title", content: defaultTitle },
    ];

    return [...metas, ...twitter, ...og];
  }, [title, description, imageHref, imageDescription, urlHref, keywords]);

  const rels = [
    { key: "canonical", rel: "canonical", href: urlHref },
    { key: "manifest", rel: "manifest", href: "/manifest.webmanifest" },
    { key: "mask-icon", rel: "mask-icon", href: "/safari-pinned-tab.svg", color },
  ];

  return (
    <Helmet {...props}>
      <title>{title}</title>

      {metatags.map((meta) => {
        if (meta.name && meta.content === undefined) {
          return null;
        } else if (meta.name && meta.content === null) {
          return <meta key={meta.key} name={meta.name} content="" />;
        } else if (meta.name) {
          return (
            <meta
              key={meta.key}
              name={meta.name}
              content={Array.isArray(meta.content) ? meta.content.join(",") : (meta.content as any)}
            />
          );
        }
        if (meta.property && meta.content === undefined) {
          return null;
        } else if (meta.property && meta.content === null) {
          return <meta key={meta.key} property={meta.property} content="" />;
        } else if (meta.property) {
          return (
            <meta
              key={meta.key}
              property={meta.property}
              content={Array.isArray(meta.content) ? meta.content.join(",") : (meta.content as any)}
            />
          );
        }
        return null;
      })}

      {robots.length > 0 && <meta key="robots" name="robots" content={robots.join(", ")} />}

      {rels.map((rel) => (
        <link {...rel} key={rel.key} />
      ))}
    </Helmet>
  );
}
